import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import H3 from '../Headings/H3Element';
import SvgIcon from '../../Components/Common/Component/SvgIcon';

const Breadcrumbs = (props) => {
  return (
    <>
      <Container fluid={true}>
        <div className="page-title">
          <Row>
            <Col xs="6">
              <H3>{props.mainTitle}</H3>
            </Col>
            <Col xs="6">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  {/*<Link to={`${process.env.PUBLIC_URL}/pages/sample-page/${layoutURL}`}>*/}
                  <Link to={`/`}>
                    <SvgIcon iconId="stroke-home" />
                  </Link>
                </li>
                {props.parent ? <li className="breadcrumb-item">{props.parent}</li> : ''}
                {props.subParent ? <li className="breadcrumb-item">{props.subParent}</li> : ''}
                <li className="breadcrumb-item active">{props.title}</li>
              </ol>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Breadcrumbs;
