import React from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';

const ReservationsNewForm = () => {
  return (
    <>
      <Row>
        <Col md="12">
          <Form>
            <FormGroup className="form-group row">
              <Label className="col-sm-3 col-form-label text-end">Room</Label>
              <Col xl="5" sm="9">
                <Input type="select" name="room" className="form-control digits" defaultValue="1">
                  <option value="1">Room 1</option>
                  <option value="2">Room 2</option>
                </Input>
              </Col>
            </FormGroup>
            <FormGroup className="form-group row">
              <Label className="col-sm-3 col-form-label text-end">Client name</Label>
              <Col xl="5" sm="9">
                <Input
                  className="form-control"
                  type="text"
                  placeholder="Client name"
                  name="client_name"
                  value="Client 1"
                />
              </Col>
            </FormGroup>
            <FormGroup className="form-group row">
              <Label className="col-sm-3 col-form-label text-end">Start date</Label>
              <Col xl="5" sm="9">
                <DatePicker
                  className="form-control digits"
                  todayButton="Today"
                  selected={new Date('8/8/2023')}
                  // onChange={handleChange}
                />
              </Col>
            </FormGroup>
            <FormGroup className="form-group row">
              <Label className="col-sm-3 col-form-label text-end">Room type</Label>
              <Col xl="5" sm="9">
                <Input
                  type="select"
                  name="room_type"
                  className="form-control digits"
                  defaultValue="1"
                >
                  <option value="1">Single</option>
                  <option value="2">Dopio</option>
                  <option value="2">Suite</option>
                </Input>
              </Col>
            </FormGroup>
            <FormGroup className="form-group row">
              <Label className="col-sm-3 col-form-label text-end">Hotel</Label>
              <Col xl="5" sm="9">
                <Input type="select" name="hotel" className="form-control digits" defaultValue="1">
                  <option value="1">Hotel 1</option>
                  <option value="2">Hotel 2</option>
                </Input>
              </Col>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ReservationsNewForm;
