import React from 'react';
import { Breadcrumbs, Btn, H5 } from '../../../AbstractElements';
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import RoomsEditForm from './RoomsEditForm';

const RoomsEdit = () => {
  return (
    <>
      <Breadcrumbs mainTitle="Room" title="Edit room" subParent="Rooms" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <H5>Edit room</H5>
                <Link to="/rooms">
                  <Btn color="primary">Back to room</Btn>
                </Link>
              </CardHeader>
              <CardBody>
                <RoomsEditForm />
              </CardBody>
              <CardFooter>
                <Btn color="primary">Create</Btn>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RoomsEdit;
