export const MENUITEMS = [
  {
    menutitle: 'General',
    menucontent: 'Dashboards,Widgets',
    Items: [
      {
        title: 'Dashboard',
        icon: 'home',
        type: 'link',
        path: '',
        active: false,
      },
      {
        title: 'Calendar',
        icon: 'calender',
        type: 'link',
        path: '/calendar',
        active: false,
      },
      {
        title: 'Reservations',
        icon: 'task',
        type: 'sub',
        children: [
          {
            title: 'Reservations',
            path: '/reservations',
            icon: 'task',
            type: 'link',
            active: false,
          },
        ],
      },
      {
        title: 'Management',
        icon: 'sample-page',
        type: 'sub',
        children: [
          {
            title: 'Hotels',
            path: '/hotels',
            type: 'link',
            active: false,
          },
          {
            title: 'Rooms',
            path: '/rooms',
            type: 'link',
            active: false,
          },
          {
            title: 'Room types',
            path: '/room-types',
            type: 'link',
            active: false,
          },
        ],
      },
      {
        title: 'Notifications',
        icon: 'email',
        type: 'link',
        path: 'notifications',
        active: false,
      },
      {
        title: 'Users',
        icon: 'user',
        type: 'sub',
        children: [
          {
            title: 'Users',
            path: '/users',
            type: 'link',
            active: false,
          },
          {
            title: 'Roles',
            path: '/roles',
            type: 'link',
            active: false,
          },
          {
            title: 'Permissions',
            path: '/permissions',
            type: 'link',
            active: false,
          },
        ],
      },
      {
        title: 'Settings',
        icon: 'project',
        type: 'link',
        path: `/settings`,
        active: false,
      },
    ],
  },
];
