import React from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';

const UsersNewForm = () => {
  return (
    <>
      <Row>
        <Col md="12">
          <Form>
            <FormGroup className="form-group row">
              <Label className="col-sm-3 col-form-label text-end">First name</Label>
              <Col xl="5" sm="9">
                <Input
                  className="form-control"
                  type="text"
                  placeholder="First name"
                  name="first_name"
                />
              </Col>
            </FormGroup>
            <FormGroup className="form-group row">
              <Label className="col-sm-3 col-form-label text-end">Last name</Label>
              <Col xl="5" sm="9">
                <Input
                  className="form-control"
                  type="text"
                  placeholder="Last name"
                  name="last_name"
                />
              </Col>
            </FormGroup>
            <FormGroup className="form-group row">
              <Label className="col-sm-3 col-form-label text-end">Email</Label>
              <Col xl="5" sm="9">
                <Input className="form-control" type="email" placeholder="Email" name="email" />
              </Col>
            </FormGroup>
            <FormGroup className="form-group row">
              <Label className="col-sm-3 col-form-label text-end">Password</Label>
              <Col xl="5" sm="9">
                <Input
                  className="form-control"
                  type="password"
                  placeholder="Password"
                  name="password"
                />
              </Col>
            </FormGroup>
            <FormGroup className="form-group row">
              <Label className="col-sm-3 col-form-label text-end">Confirm password</Label>
              <Col xl="5" sm="9">
                <Input
                  className="form-control"
                  type="password"
                  placeholder="Confirm password"
                  name="password_confirmation"
                />
              </Col>
            </FormGroup>
            <FormGroup className="form-group row">
              <Label className="col-sm-3 col-form-label text-end">Role</Label>
              <Col xl="5" sm="9">
                <Input type="select" name="role" className="form-control digits" defaultValue="2">
                  <option value="1">Admin</option>
                  <option value="2">User</option>
                </Input>
              </Col>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default UsersNewForm;
