import React, { useCallback, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Btn, H4 } from '../../../AbstractElements';
import { Link } from 'react-router-dom';

export const rolesColumns = [
  {
    name: 'Name',
    selector: (row) => row['name'],
    sortable: true,
    center: false,
  },
  {
    name: 'Created at',
    selector: (row) => `${row.created_at}`,
    sortable: true,
    center: true,
  },
  {
    name: 'Actions',
    selector: (row) => row['actions'],
    sortable: false,
    center: true,
    width: '240px',
  },
];

export const usersData = [
  {
    id: 1,
    name: 'Users View',
    created_at: '2023/04/27',
    actions: (
      <div style={{ display: 'flex', gap: '0.2rem' }}>
        <Link to="/permissions/1/edit">
          <Btn color="primary">Edit</Btn>
        </Link>
        <Btn color="danger">Delete</Btn>
      </div>
    ),
  },
];

const PermissionsTable = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelete, setToggleDelet] = useState(false);
  const [data, setData] = useState(usersData);

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleDelete = () => {
    if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map((r) => r.title)}?`)) {
      setToggleDelet(!toggleDelete);

      setData(
        data.filter((item) => selectedRows.filter((elem) => elem.id === item.id).length <= 0)
      );
      setSelectedRows('');
    }
  };
  return (
    <>
      {selectedRows.length !== 0 && (
        <div className={`d-flex align-items-center justify-content-between bg-light-info p-2`}>
          <H4 attrH4={{ className: 'text-muted m-0' }}>Delete Selected Data..!</H4>
          <Btn color="danger" onClick={handleDelete}>
            Delete
          </Btn>
        </div>
      )}
      <DataTable
        data={data}
        columns={rolesColumns}
        striped={true}
        center={true}
        pagination
        selectableRows
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleDelete}
      />
    </>
  );
};
export default PermissionsTable;
