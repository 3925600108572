import Dashboard from '../Components/Pages/Dashboard/Dashboard';
import Calendar from '../Components/Pages/Calendar/Calendar';
import Reservations from '../Components/Pages/Reservations/Reservations';
import ReservationsNew from '../Components/Pages/Reservations/ReservationsNew';
import ReservationsEdit from '../Components/Pages/Reservations/ReservationsEdit';
import Hotels from '../Components/Pages/Hotels/Hotels';
import HotelsNew from '../Components/Pages/Hotels/HotelsNew';
import HotelsEdit from '../Components/Pages/Hotels/HotelsEdit';
import Rooms from '../Components/Pages/Rooms/Rooms';
import RoomsNew from '../Components/Pages/Rooms/RoomsNew';
import RoomsEdit from '../Components/Pages/Rooms/RoomsEdit';
import RoomTypes from '../Components/Pages/RoomTypes/RoomTypes';
import RoomTypesNew from '../Components/Pages/RoomTypes/RoomTypesNew';
import RoomTypesEdit from '../Components/Pages/RoomTypes/RoomTypesEdit';
import Notifications from '../Components/Pages/Notifications/Notifications';
import Users from '../Components/Pages/Users/Users';
import Roles from '../Components/Pages/Roles/Roles';
import Permissions from '../Components/Pages/Permissions/Permissions';
import Settings from '../Components/Pages/Settings/Settings';
import UsersNew from '../Components/Pages/Users/UsersNew';
import UsersEdit from '../Components/Pages/Users/UsersEdit';
import RolesNew from '../Components/Pages/Roles/RolesNew';
import RolesEdit from '../Components/Pages/Roles/RolesEdit';
import PermissionsNew from '../Components/Pages/Permissions/PermissionsNew';
import PermissionsEdit from '../Components/Pages/Permissions/PermissionsEdit';

export const routes = [
  { path: `/`, Component: <Dashboard /> },
  { path: `/calendar`, Component: <Calendar /> },
  { path: `/reservations`, Component: <Reservations /> },
  { path: `/reservations/new`, Component: <ReservationsNew /> },
  { path: `/reservations/1/edit`, Component: <ReservationsEdit /> },
  { path: `/hotels`, Component: <Hotels /> },
  { path: `/hotels/new`, Component: <HotelsNew /> },
  { path: `/hotels/1/edit`, Component: <HotelsEdit /> },
  { path: `/rooms`, Component: <Rooms /> },
  { path: `/rooms/new`, Component: <RoomsNew /> },
  { path: `/rooms/1/edit`, Component: <RoomsEdit /> },
  { path: `/room-types`, Component: <RoomTypes /> },
  { path: `/room-types/new`, Component: <RoomTypesNew /> },
  { path: `/room-types/1/edit`, Component: <RoomTypesEdit /> },
  { path: `/notifications`, Component: <Notifications /> },
  { path: `/users`, Component: <Users /> },
  { path: `/users/new`, Component: <UsersNew /> },
  { path: `/users/1/edit`, Component: <UsersEdit /> },
  { path: `/roles`, Component: <Roles /> },
  { path: `/roles/new`, Component: <RolesNew /> },
  { path: `/roles/1/edit`, Component: <RolesEdit /> },
  { path: `/permissions`, Component: <Permissions /> },
  { path: `/permissions/new`, Component: <PermissionsNew /> },
  { path: `/permissions/1/edit`, Component: <PermissionsEdit /> },
  { path: `/settings`, Component: <Settings /> },
];
