import React, { Fragment } from 'react';
import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import { configureFakeBackend } from '../Services/fack.backend';
// import Callback from '../Auth/Callback';
import Loader from '../Layout/Loader';
// import LayoutRoutes from '../Route/LayoutRoutes';
import Signin from '../Auth/Signin';
import PrivateRoute from './PrivateRoute';
// import { classes } from '../Data/Layouts';
// import { authRoutes } from './AuthRoutes';
import ErrorPage404 from '../Components/Pages/ErrorPages/ErrorPage404';
import { routes } from './Routes';
import AppLayout from '../Layout/Layout';

// setup fake backend
// configureFakeBackend();
const Routers = () => {
  // const login = useState(JSON.parse(localStorage.getItem('login')))[0];
  // const [authenticated, setAuthenticated] = useState(false);
  // const jwt_token = localStorage.getItem('token');
  // const defaultLayoutObj = classes.find((item) => Object.values(item).pop(1) === 'compact-wrapper');
  // const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

  // useEffect(() => {
  //   let abortController = new AbortController();
  //   const requestOptions = { method: 'GET', headers: authHeader() };
  //   fetch('/users', requestOptions).then(handleResponse);
  //
  //   setAuthenticated(JSON.parse(localStorage.getItem('authenticated')));
  //   console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
  //   console.disableYellowBox = true;
  //   return () => {
  //     abortController.abort();
  //   };
  // }, []);

  return (
    <BrowserRouter basename={'/'}>
      <>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path={'/'} element={<PrivateRoute />}>
              {/*{login || authenticated || jwt_token ? (*/}
              {/*  <>*/}
              {/*    <Route exact path={`${process.env.PUBLIC_URL}`} element={<Navigate to={`${process.env.PUBLIC_URL}/pages/sample-page/${layout}`} />} />*/}
              {/*    <Route exact path={`/`} element={<Navigate to={`${process.env.PUBLIC_URL}/pages/sample-page/${layout}`} />} />*/}
              {/*  </>*/}
              {/*) : (*/}
              {/*  ''*/}
              {/*)}*/}
              {/*<Route path={`/`} element={<LayoutRoutes />} />*/}
              {routes.map(({ path, Component }, i) => (
                <Fragment key={i}>
                  <Route element={<AppLayout />} key={i}>
                    <Route path={path} element={Component} />
                  </Route>
                </Fragment>
              ))}
            </Route>
            {/*<Route path={`${process.env.PUBLIC_URL}/callback`} render={() => <Callback />} />*/}
            {/*<Route exact path={`${process.env.PUBLIC_URL}/login`} element={<Signin />} />*/}
            <Route exact path={`/login`} element={<Signin />} />
            {/*{authRoutes.map(({ path, Component }, i) => (*/}
            {/*  <Route path={path} element={Component} key={i} />*/}
            {/*))}*/}
            <Route path="*" element={<ErrorPage404 />} />
          </Routes>
        </Suspense>
      </>
    </BrowserRouter>
  );
};

export default Routers;
