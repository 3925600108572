import React from 'react';
import { Breadcrumbs, H1 } from '../../../AbstractElements';
import { Col, Container, Row } from 'reactstrap';

const Dashboard = () => {
  return (
    <>
      <Breadcrumbs mainTitle="Hotel management system" title="Dashboard" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <H1>Welcome</H1>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
