import React from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';

const RoomsEditForm = () => {
  return (
    <>
      <Row>
        <Col md="12">
          <Form>
            <FormGroup className="form-group row">
              <Label className="col-sm-3 col-form-label text-end">Name</Label>
              <Col xl="5" sm="9">
                <Input
                  className="form-control"
                  type="text"
                  placeholder="Name"
                  name="name"
                  value="Room 1"
                />
              </Col>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default RoomsEditForm;
