import React, { Fragment } from 'react';
import { Breadcrumbs, H5 } from '../../../AbstractElements';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import DragCalendar from '../../Calendar/DraggableCalendar/DragCalendar';

const Calendar = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Calendar" title="Calendar" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <H5>Calendar</H5>
              </CardHeader>
              <CardBody>
                <div className="basic-calendar">
                  <Row>
                    <DragCalendar />
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Calendar;
