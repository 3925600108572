import React from 'react';
import { Breadcrumbs, H1 } from '../../../AbstractElements';
import { Col, Container, Row } from 'reactstrap';

const Settings = () => {
  return (
    <>
      <Breadcrumbs mainTitle="Settings" title="Settings" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <H1>Settings</H1>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Settings;
