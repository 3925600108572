import React from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import { Container, Row } from 'reactstrap';
import HoverableRowsClass from './HoverableRows';

const Notifications = () => {
  return (
    <>
      <Breadcrumbs mainTitle="Notifications" title="Notifications" />
      <Container fluid={true}>
        <Row>
          {/*<Col sm="12">*/}
          {/*<Card>*/}
          {/*  <CardHeader>*/}
          {/*    <H5>Notifications</H5>*/}
          {/*  </CardHeader>*/}
          {/*  <CardBody>*/}
          <HoverableRowsClass />
          {/*</CardBody>*/}
          {/*</Card>*/}
          {/*</Col>*/}
        </Row>
      </Container>
    </>
  );
};

export default Notifications;
